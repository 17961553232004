import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import Axios from "axios";
import SuccessHistoryDetailsContent from "../components/successHistory/SuccessHistoryDetailsContent";

const SuccessHistoryDetails = ({
  match: {
    params: { id },
  },
}) => {
  const [successHistory, setSuccessHistory] = useState({});

  const getOneSuccessHistory = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getsuccessHistory = async () => {
      const res = await Axios.get(
        `${process.env.REACT_APP_API_URL}/success-histories/${id}`,
        {
          signal,
        }
      );

      if (res.status === 404) {
        setSuccessHistory({});
      } else {
        setSuccessHistory(res.data.data);
      }
    };
    getsuccessHistory();
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    getOneSuccessHistory();
  }, []);

  return (
    <>
      <SEO title="Success History Overview   || iCAN" />
      <Layout>
        <div className="rn-blog-details-area">
          <SuccessHistoryDetailsContent data={successHistory} />
        </div>
      </Layout>
    </>
  );
};
export default SuccessHistoryDetails;
