import React, { useEffect, useState } from "react";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import ApiService from "../../apiService/apiservice";

const ContactOne = () => {
  const [contacts, setContacts] = useState({
    phoneNumbers: [],
    email: "",
    locations: [],
    latitude: 0,
    longitude: 0,
  });

  useEffect(() => {
    ApiService.get(`/contacts`)
      .then((res) => {
        let { phoneNumbers, email, locations, latitude, longitude } =
          res.data[0];

        // Normalize phoneNumbers if it's a string
        if (typeof phoneNumbers === "string") {
          phoneNumbers = phoneNumbers.split(",").map((number) => number.trim());
        }

        if (typeof locations === "string") {
          locations = locations.split(",").map((loc) => loc.trim());
        }

        setContacts({ phoneNumbers, email, locations, latitude, longitude });
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
      });

  }, []);

  return (
    <>
      <div className="row row--15 contact-info-container">
        <div className="col-lg-4 col-md-6 col-12">
          <div className="rn-address h-100">
            <div className="icon">
              <FiHeadphones />
            </div>
            <div className="inner">
              <h4 className="title">Phone Numbers</h4>
              {contacts.phoneNumbers && contacts.phoneNumbers.length > 0 && (
                <p>
                  <a href={`tel:${contacts.phoneNumbers.join(",")}`}>
                    {contacts.phoneNumbers
                      .map((number) => number.replace(/[\"\[\]\s]/g, "").trim())
                      .join(", ")}
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12">
          <div className="rn-address h-100">
            <div className="icon">
              <FiMail />
            </div>
            <div className="inner">
              <h4 className="title">Email Address</h4>
              <p>
                <a href={`mailto:${contacts.email}`}>{contacts.email}</a>
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12">
          <div className="rn-address h-100">
            <div className="icon">
              <FiMapPin />
            </div>
            <div className="inner">
              <h4 className="title">Our Locations</h4>
              {contacts.locations &&
                contacts.locations.length > 0 &&
                contacts.locations.map((location, index) => (
                  <p key={index}>
                    <strong>{`Branch ${index + 1}`} : </strong>
                    {location.replace(/["\[\]]/g, "")}
                  </p>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        <div className="col-lg-7">
          <ContactForm formStyle="contact-form-1" />
        </div>
        <div className="col-lg-5 mt_md--30 mt_sm--30">
          <GoogleMapStyle
            lat="23.75471757614277"
            lng="90.38938684219217"
            zoom={3}
          />
        </div>
      </div>
    </>
  );
};

export default ContactOne;
