import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApiService from "../../apiService/apiservice";

const Nav = () => {
  const [courseList, setCourseList] = useState([]);

  useEffect(() => {
    ApiService.get(`/courses`)
      .then((response) => {
        setCourseList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
      });
  }, []);

  return (
    <ul className="mainmenu">
      <li className="has-droupdown">
        <Link to="/">Home</Link>
      </li>

      <li>
        <Link to="/about-us">About</Link>
      </li>

      <li className="has-droupdown">
        <Link to="#">Courses</Link>
        <ul className="submenu">
          {courseList &&
            courseList.length > 0 &&
            courseList.map((course, index) => (
              <li key={index}>
                <Link to={`/course-details/${course.id}`}>{course.name}</Link>
              </li>
            ))}
        </ul>
      </li>

      <li className="has-droupdown">
        <Link to="#">News & Notices</Link>
        <ul className="submenu">
          <li>
            <Link to="/news">News</Link>
          </li>
          <li>
            <Link to="/events">Events</Link>
          </li>
        </ul>
      </li>

      <li>
        <Link to="/gallery">Gallery</Link>
      </li>

      <li>
        <Link to="/success-history">Our Success</Link>
      </li>

      <li className="has-droupdown">
        <Link to="/contact">Contact</Link>
      </li>
    </ul>
  );
};
export default Nav;
