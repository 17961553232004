import React from "react";
import { slugify } from "../../../utils";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const SuccessHistoryList = ({ data, StyleVar }) => {
  return (
    <div className={`rn-card ${StyleVar}`}>
      <div className="inner">
        <div className="thumbnail">
          <Link
            to={process.env.PUBLIC_URL + `/success-history-details/${data.id}`}
            className="image"
          >
            <img
              src={`${process.env.REACT_APP_BASE_URL}/${
                data &&
                data.image.substring(data.image.lastIndexOf("public/uploads/"))
              }`}
              alt="News Image"
            />
          </Link>
        </div>
        <div className="content">
          <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
        </div>
      </div>
    </div>
  );
};

SuccessHistoryList.propTypes = {
  data: PropTypes.object,
};

export default SuccessHistoryList;
